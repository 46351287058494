//font imports
@import url("https://use.typekit.net/cso7jot.css");

// fonts
$font: 'proxima-nova';

//font weights
$light: 300;
$med: 400;
$semi-bold: 600;

// project colors
$teal: #72a3a8;
$navy: #0d2233;
$red: #cf3230;
$trans-red: rgba(207,50,48,0.66);
$trans-blue: rgba(13,34,51,0.9);
$khaki: #cdcdcd;

//usual colors
$white: #fff;
$black: #000;
$trans-white: rgba(255,255,255,0.75);
$dark-gray: #565656;
$trans-gray: rgba(86,86,86,0.75);

// shadows and gradients
$bottom-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.37);
$top-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.37);

$gradient:linear-gradient(to right, $trans-red 55%,rgba(114,5,154,0) 100%);