*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  //testing
  //border: 1px solid black;
}

*::selection {
  background-color: $red;
  color: $white;
}

h1, h2, h3, h4, h5, h6, p, ul > li, ol > li, a, blockquote, table>th, table>td {
  font-family: $font;
  font-weight:$med;
  letter-spacing: 1px;
}

strong {
  font-weight:$semi-bold;
}

h1, h4 {
  text-transform: uppercase;
}

.img-responsive {
  max-width: 100%;
}

.btn-teal {
  @include button-bg($teal, $white);
}

.btn-red {
  @include button-bg($red, $white);
}

.btn-blue {
  @include button-bg($navy, $white);
}

.btn-blue-inv {
  @include button-bg($white, $navy);
  border-radius: 3px;
  border: 1px solid $navy;
  &:hover {
    background-color: $navy;
    color: $white;
    border: 1px solid $white;
    transition: all 0.25s linear;
  }
}

.table-striped-blue {
  @include table-striped($khaki, $dark-gray);
}

.social {
  display: inline-block;
  a {
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 5px;
    margin-left:5px;
    margin-right: 5px;
    margin-top: 5px;
    background: url('../img/social-sprite-white.png') no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
    &:hover, &:focus {
      opacity: 0.8;
      transition: all 0.25s ease;
    }
  }
}


//Red Social Bar
#social-bar {
  background-color: $red;
  text-align: right;
  .btn-blue {
    margin: -10px 0px 0px 0px;
    padding: 13px;
    border: 0px;
  }
  .social {
    a#si-fb {
      background-position-x: -40px;
    }
    a#si-search {
      background-position-x: -78px;
    }
    a#si-phone {
      background-position-x: -120px;
    }
    a#si-home {
      background-position-x: -169px;
    }
    a#si-email {
      background-position-x: -215px;
    }
  }
}


//Primary Nav
nav.navbar {

  .navbar-collapse {
    .navbar-nav {
      li {
        a {
          color: $navy;
          transition: all 0.25s ease;
          text-transform: uppercase;
          padding-left: 10px;
          display: inline-block;
          text-decoration: none;
          width: 100%;
          &:hover, &:focus {
            color: $teal;
            transition: all 0.5s ease;
          }
        }
      }
      .dropdown-menu {
        border-radius: 0px;
        li {
          a::after {
            content: "";
            position: absolute;
            left: 10px;
            height: 1px;
            margin: 21px 0 0 0;
            width: 85%;
            border-bottom: 1px solid rgba(86, 86, 86, 0.2);
          }
          &:hover, &:focus {
            background-color: $navy;
          }
        }
      }
    }
  }
}

.navbar-toggler {
  background-color: $trans-red;
  position: absolute;
  z-index: 1;
  transform: translate(-50%);
  left: 50%;
  color: $white;
  padding: 5px 10px;
  border-radius: 0px 0px 10px 10px;
  transition: all 0.25s ease;
  .navbar-toggle-custom {
    font-size: 16px;
    text-transform: uppercase;
  }
  &[aria-expanded=true] {
    background-color: rgba(207,50,48,0.4);
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.15s ease;
  }
}


//Home Hero Carousel
#hero {
  padding-left: 0px;
  padding-right: 0px;
  .carousel {
    .carousel-indicators {
      li {
        flex:none;
        max-width: auto;
        width: 10px;
        height: 10px;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 100%;
        &.active::before {
          top: -5px;
          left: -5px;
          width: 20px;
          height: 20px;
          background-color: transparent;
          border: 2px solid $white;
          border-radius: 100%;
        }
      }
    }
    .carousel-inner {
      .carousel-item {
        max-height: 85vh;
        img {
          width: 100%;
          object-fit: cover;
        }
        .carousel-caption {
          width: 100%;
          left:auto;
          right:auto;
          bottom:-150px;
          transition: all 2s linear;
          //-webkit-transition: all 2s linear;
          h3, p {
            font-weight:$light;
            position: absolute;
            left:-100%;
          }
          &::before {
            content: '';
            position: absolute;
            width:100%;
            height: 150px;
            background-color: $trans-red;
            bottom: 0px;
            left: 0px;
            z-index:-1;
            transition: all 1s linear;
            //-webkit-transition: none;
          }
        }
        &.active {
          .carousel-caption {
            bottom: 0px;
            transition: all 0.18s linear;
            //-webkit-transition: none!important;
            h3, p {
              display: block;
              position: relative;
              left:0px;
              transition: all 0.25s ease 0.23s;
              //-webkit-transition: none;
            }
          }
        }
      }
    }
  }
}


//Home Events
#event-title{
  background-color: $khaki;
  border-top: 2px solid $white;
  position: relative;
  text-align: center;
  padding-top: 25px;
  padding-bottom:1px;
  h4, p>a {
    color: $white;
    font-weight: $light;
  }
  &::before {
    position: absolute;
    content: '';
    z-index:0;
    width: 100%;
    height: 100%;
    left:0px;
    top:0px;
    background-color: $navy;
    clip-path: polygon(0 0, 84% 0, 100% 100%, 0% 100%);
  }
}
#events {
  margin-top: 15px;
  .slick-prev, .slick-next {
    background-color: $red;
    height: 30px;
    width: 30px;
    z-index:10;
  }
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-slide {
    padding: 3px;
    img {
      max-width: 100%;
    }
  }
}


//Home Issues
#issues{
  padding-top: 50px;
  h4 {
    color: $navy;
  }
}
#medallions {
  text-align: center;
  padding-bottom: 50px;
  .medallion {
    max-width: 165px;
    .medallion-text {
      color: $navy;
      text-transform: uppercase;
      text-decoration: none;
      line-height:1.1rem;
      top: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}
#medallions {
  .work-medallions {
    .medallion {
      margin: 25px;
    }
  }
}


//Home Manufacturers Block
#tmma{
  #tmma-blue,#tmma-gray {
    padding: 50px;
    text-align: center;
  }
  #tmma-gray {
    background-color: $khaki;
    padding-bottom: 75px;
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      background: url('../img/tma-logo.jpg') no-repeat center center;
      background-color: $white;
      height: 150px;
      width: 60vw;
      border-radius: 100%;
      background-size: 90%;
      left: 19vw;
      bottom: -80px;
    }
  }
  #tmma-blue {
    background-color: $navy;
    padding-top: 100px;
    p {
      color: $white;
    }
  }
}


//Footer
.footer {
  background: url('../img/footer-bg.png') no-repeat bottom;
  background-size: cover;
  background-color: $white;
  padding-bottom: 20px;
  #partner-logos {
    flex-wrap: nowrap;
    justify-content: space-between;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 22%;
      padding: 10px;
      &#us-chamber-wrap {
        padding: 4vw;
      }
      img {
        max-width: 100%;
      }
      &:nth-child(2) {
        max-width: 45%;
      }
    }
  }
  .footer-logo {
    display: block;
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .address, .mission {
    text-align: center;
  }
  .mission {
    font-style: oblique;
    font-size: 12px;
    line-height: 1.2em;
  }
  .social.footer-social {
    text-align: center;
    display: block;
    a.social-icon {
      background: url('../img/social-sprite-blue.png') no-repeat center;
      background-size: cover;
      &#ft-si-tw {
        background-position-x: -35px;
      }
      &#ft-si-fb {
        background-position-x: 9px;
      }
    }
  }
}
#copy{
  background-color: $navy;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  p {
    color: $white;
    font-size: 12px;
  }
}

//interior styles
#int-header {
  background-color: $khaki;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background-color: $navy;
    clip-path: polygon(0 0, 84% 0, 100% 100%, 0% 100%);
    width: 90%;
    height: 100%;
    left: 0;
  }
  .title-wrap {
    padding: 10vh 10% 10vh 10px;
  }
  h1 {
    text-transform: none;
    color: $white;
    font-weight:$light;
    line-height: 3rem;
  }
  p {
    color: $white;
  }
}

.int-body {
  padding-top: 50px;
  h2, h3, h4, h5 {
    color: $teal;
    font-weight:$light;
  }
  h3 {
    color: $navy;
  }
  p, ul>li, ol>li {
    font-weight: $light;
  }
  ul>li {
    margin-bottom: 5px;
  }
  //Data Block/Toggle Block Styles
  .data-block {
    animation: fadein 0.25s linear forwards;
    &:not(:first-child) {
      display: none;
    }
    @keyframes fadein {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }
  //Left Nav Styles
  &#left-nav-body {
    .left-nav {
      list-style-type: none;
      overflow: hidden;
      padding-left:0px;
      li {
        margin-bottom: 0;
        a {
          text-decoration: none;
          color: $dark-gray;
          text-transform: uppercase;
          display: block;
          transition: all 0.25s ease;
          position: relative;
          padding: 5px;
          &::after {
            content: '';
            position: absolute;
            z-index:-1;
            background-color: $teal;
            width: 100%;
            height:100%;
            left: -100%;
            top: 0;
            clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
            transition: all 0.25s ease;
          }
          &.active {
            color: $white;
            &::after {
              left:0;
              transition: all 0.25s ease;
            }
          }
        }
      }
    }
  }

  //Staff Layout
  .staff-wrap {
    .dept {
      h2 {
        width: 100%;
        margin-top: 10px;
      }
      .staff-block {
        line-height: 1em;
        padding: 15px;
        img {
          width: 100%;
          border-radius: 100%;
          margin-bottom: 15px;
        }
        h3, p {
          margin-bottom: 1px;
        }
        h3 {
          text-transform: uppercase;
          color: $dark-gray;
          font-size: 1rem;
          font-weight: 600;
        }
        p, a {
          color: $dark-gray;
        }
        .staff-title {
          color: $teal;
          font-size: 0.8rem;
          margin-bottom: 7.5px;
          font-weight: 600;
        }
        .staff-email {
          font-size: .85rem;
        }
      }
    }
  }
}
//Investor Styles
.investor-header {
  margin: 50px 0;
  font-weight: 500;
}
.investor-image-container {
  display: flex;
  flex-wrap: wrap;
  a {
    align-self: center;
    border-radius: 100%;
    width: 49%;
    img {
      max-width: 100%;
      border-radius: 100%;
      margin-bottom: 25px;
    }
  }
}
.investor-text-contain {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .link-contain {
    width: 33%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1em;
    a {
      border-radius: 100%;
      align-self: center;
      img {
        border-radius: 100%;
      }
    }
  }
}

//interior button layout
.button-flex-container {
  display:  flex;
  flex-wrap: wrap;
  justify-content: center;
  .btn {
    flex: 1 auto;
    margin: 5px;
    min-width: 190px;
    max-width: 33%;
  }
}
//partner discounts layout
.partner-discount {
  margin: 20px 0 30px;
  clear: both;
  min-height: 200px;
  .partner-discount-image {
    float: left;
    width: 200px;
    height: 200px;
    margin-right: 50px;
  }
}