@media only screen and (min-width: 992px) {
  #hero .carousel .carousel-inner .carousel-item .carousel-caption::before {
    width: 44%;
  }
  #hero .carousel .carousel-inner .carousel-item .carousel-caption {
    h3 {
      font-size: 3.5vw;
    }
    p {
      font-size: 1.75vw;
    }
  }
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-toggler {
      display: none;
  }
  .dropdown-menu {
    top: 65px;
    border: 0px;
    background-color: $trans-white;

  }
  #tmma {
    background-position-x: right;
    border-bottom: 50px solid $white;
    #tmma-gray, #tmma-blue {
      text-align: left;
    }
    #tmma-gray {
      padding-right: 180px;
      &::after {
        bottom: auto;
        left: auto;
        right: -150px;
        top: 50%;
        transform: translateY(-50%);
      }
      //Extends khaki bar full with - not sure if needed
      //&::before {
      //  content: '';
      //  position: absolute;
      //  width: 100vw;
      //  z-index:-1;
      //  height: 100%;
      //  background-color: $khaki;
      //  transform: translateX(-100%);
      //}
    }
    #tmma-blue {
      padding-left: 180px;
    }
  }

    .footer {
      #partner-logos {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 150px;
        a#us-chamber-wrap {
          padding: 10px;
        }
      }
    }
}
@media only screen and (min-width : 1200px) {
  #int-header {
    &::before {
      width: 50%;
    }
    .title-wrap {
      width: 38%;
      padding-right: 0px;
    }
  }
  .int-body {
    .data-block {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  #tmma {
    text-align: left;
  }
  .container.home-container {
    width: 1400px;
  }
  #tmma #tmma-gray p {
    max-width: 670px;
    float: right;
  }
  #tmma #tmma-blue p {
    max-width: 525px;
  }
}