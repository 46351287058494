@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
.medallion {
  position: relative;
  display: inline-block;
  margin: 20px;
  max-width: 190px;
  width: 100%;
  color: #bbb;
  font-size: 16px;
  box-shadow: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.medallion *,
.medallion:before,
.medallion:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.medallion:before,
.medallion:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  border: 3px solid $teal;
  border-color: transparent $teal;
}

.medallion img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
  border-radius: 50%;
  padding: 10px;
}

.medallion figcaption {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  opacity: 0;
  background-color: $trans-white;
  border-radius: 50%;
}

.medallion i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 4em;
  z-index: 1;
}

.medallion a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.medallion:hover figcaption,
.medallion.hover figcaption {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.medallion:hover:before,
.medallion.hover:before,
.medallion:hover:after,
.medallion.hover:after {
  border-width: 10px;
}

.medallion:hover:before,
.medallion.hover:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.medallion:hover:after,
.medallion.hover:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}


//ie11 styles
.ie11 {
  .medallion {
    img {
      padding: 0px;
    }
  }
}


