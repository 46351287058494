@mixin button-bg($bg, $text) {
  background: $bg;
  border: 1px solid $text;
  border-radius: 0;
  transition: all 0.3s ease;
  font-weight: $light;
  margin-top:10px;
  margin-bottom:10px;
  text-transform: uppercase;
  color: $text;
  &:hover, &:focus {
    background:lighten($bg,8%);
    transition: all 0.3s ease;
    color: $text;
  }
  &:active {
    background:darken($bg,25%);
  }
}


@mixin table-striped($bg, $font) {
  & > tbody {
    & > tr:nth-of-type(odd) {
      background-color: $bg;
    }
    & > tr:nth-of-type(even) {
      background-color: lighten($bg,5%);
    }
    & > tr > td {
      border: 0;
      text-align: left;
      color: $font;
    }
    & > tr:not(:first-child) > td:not(:first-child) {
      text-align: right;
    }
  }
}
