@media only screen and (min-width: 576px) {

  #tmma {
    background: url('../img/tma-background.jpg') no-repeat bottom;
    background-size: contain;
    #tmma-gray {
      &::after {
        width: 300px;
        height: 170px;
        transform: translate(-50%);
        left: 50%;
      }
    }
    #tmma-blue {
      background-color: $trans-blue;
    }
  }
}


@media only screen and (min-width: 768px) {
  //Home Hero Carousel
  #hero {
    .carousel {
      .carousel-indicators {
        bottom: 5vh;
        li {
          width: 20px;
          height: 20px;
          margin-left: 7px;
          margin-right: 7px;
          &.active::before {
            top: -5px;
            left: -5px;
            width: 30px;
            height: 30px;
            transition: border 0.1s ease;
          }
        }
      }
      .carousel-inner {
        .carousel-item {
          .carousel-caption {
            height:100%;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            bottom:-300%;
            &::before {
              left: 0%;
              height: 300%;
              width: 50%;
              clip-path: polygon(0 0, 0% 100%, 100% 100%);
            }
            h3, p {
              margin-left: 40px;
            }
            h3 {
              font-size: 4vw;
            }
            p {
              font-size: 2vw;
            }
          }
        }
      }
    }
  }

  //Home Events
  #event-title{
    #event-title-container {
      display:flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &::before {
      width: calc(50% - 100px);;
    }
  }


  //Interior Styles
  #int-header {
    &::before {
      width: 42vw;
    }
    .title-wrap {
      padding-right: 52vw;
    }
  }
  .int-body {
    padding-bottom: 50px;
    //Staff Layout
    .staff-wrap {
      .dept {
        justify-content: flex-start!important;
        .staff-block {
          width: 250px;
          padding: 20px;
        }
      }
    }

  }
  //Investor Styles
  .investor-image-container{
    a {
      width: 24%;
    }
  }
  .investor-text-contain {
    .link-contain {
      width: 16%;
    }
  }
  // Work Medallions
  #medallions {
    .work-medallions {
      .medallion {
        max-width: 190px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    .navbar-brand {
      text-align: center;
    }
  }

}