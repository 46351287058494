.ie11 {
  #hero {
    //padding-left: 0px;
    //padding-right: 0px;
    .carousel {
      .carousel-inner {
        .carousel-item {
          img {
          }
          .carousel-caption {
            width: 50%;
            left:0px;
            top: 0px;
            h3, p {
              left:0px;
            }
            &::before {
              content: none;
            }
          }
          &.active {
            .carousel-caption {

              h3, p {
              }
              &::before {
                content: '';
                z-index:-1;
                background-color: $trans-red;
                width: 100%;
                height:100%;
                transition: none;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    #partner-logos {
      display: block;
      text-align: center;
      a {
        display: inline-block;
        &::nth-child(2) {
          max-width: auto;
        }

      }
    }
  }

}